.header {
  max-height: 300px;
  width: 100%;
  opacity: 1;
  background-color: rgba(51, 51, 51, 1);
}


.navbar-nav {
  margin-left: auto;
}

.navbar-nav .nav-item {
  padding-left: 5px;
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
  height: 56px;
}

.navbar button {
  border-radius: 0;
  height: 56px;
  padding-left: 24px;
  padding-right: 24px;
  margin-right: -24px;
  margin-left: 24px;
}


@media screen and (max-width: 768px) {
  .navbar button {
    margin-left: 30px;
    margin-right: -36px;
  }

  .search-bar {
    display: none;
  }
}

.subHeader {
  margin-top: 20px;
  margin-bottom: 00px;
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: 84px;
  max-height: 84px;
  box-shadow: 0px 2px 6px rgba(51, 51, 51, 0.25);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
}

.subHeader .menu a {
  font-size: 1.0em;
  font-weight: normal;
  text-decoration-line: none;
  text-decoration-style: solid;
  text-decoration-thickness: auto;
  color: rgb(118, 118, 118);
  text-decoration-color: rgb(118, 118, 118);
  padding-left: 25px;
}

.subHeader .menu a:hover {
  color: #0758ac;
}

.search {
  max-width: 220px;
  width: 220px;
}