.unauth-unlock-rectangle {
  position: relative;
  width: 100%;
  height: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.centered-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.text-normal {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #767676;
  margin-top: 320px;
  margin-left: 48px;
}

.text-large {
  font-size: 32px; /* Smaller size for mobile */
  font-weight: bold;
  padding-bottom: 20px;
}

.cls-1 {
  fill: #353534;
}

.cls-2 {
  fill: #D5262E;
}

@media (max-width: 768px) {
  .unauth-unlock-rectangle {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .text-large {
    font-size: 24px; /* Smaller size for mobile */
    font-weight: bold;
    padding-bottom: 16px;
  }
}