div.hero-anonymous {
    z-index: -1;
    position: absolute;
    left: 0;
    width: 100%;
    height: 550px;
    background-image: url("/public/hero-anonymous.jpg");
    background-position: 0 -75px;
    background-size: cover;
    background-color: #ffffff;
}

.title {
    margin-top: 75px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 2px 18px 0;
    padding: 52px 35px 52px 35px;
    width: 50%;
    background-color: #ffffff;
    margin-bottom: 150px;
}

.authenticated-title {
    position: relative;
    top: 50px;
    left: 50px;
    background-color: #ffffff;
}

.authenticated-title p {
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 768px) {
  .unauth-hero, .unauth-home-upper-text, .unauth-unlock-rectangle, .get-started-container {
    flex-direction: column;
    align-items: center;
    width: 100%; /* Adjust the width as needed */
    margin-top: 0;
    text-align: center;
  }

  .unauth-home-upper-text {
    top: 0;
    left: 5px;
    margin-top: 0;
  }

  .unauth-unlock-rectangle {
    flex-direction: column;
    align-items: center;
    width: 100%; /* Adjust the width as needed */
    margin-top: 0;
  }

  .get-started-container {
    margin-top: 0;
  }
}

.product-container {
    background-color: #f5f5f5;
    padding: 45px;
    margin-bottom: 45px;
}

.detail img {
    width: 100%;
    background-size: cover;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 2px 18px 0;
    margin-bottom: 75px;
}

.detail {
    margin-bottom: 400px;
}

.unauth-hero {
  position: relative;
  z-index: 1;
}

.unauth-home-upper-text {
  position: relative;
  top: -50px;
  left: 50px;
  z-index: 2;
  overflow: hidden;
  color: black; /* Ensure text color is visible */
}

.unauth-unlock-rectangle {
  position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: -550px;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
}