.root-record {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: content;
    text-align: left;
    padding: 10px;
}

@media screen and (max-width: 768px) {
    .root-record.h2 {
        font-size: 1.3em;
    }

    .root-record.p {
        font-size: 1.0em;
    }
}