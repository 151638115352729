.unauth-hero {
  width: 100%;
}

@media (max-width: 768px) {
  .unauth-hero {
  }

  .unauth-hero-image {

  }
}