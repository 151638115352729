.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.unauth-hero {
  position: relative;
  z-index: 1;
}

.unauth-home-upper-text {
  position: relative;
  top: -50px;
  left: 50px;
  z-index: 2;
  overflow: hidden;
  color: black; /* Ensure text color is visible */
}

.unauth-unlock-rectangle {
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: -550px;
}

@media screen and (max-width: 768px) {
  .unauth-hero, .unauth-home-upper-text, .unauth-unlock-rectangle, .get-started-container {
    text-align: center;
    position: relative;
    left: 0;
    top: 0;
  }

  .unauth-home-upper-text {
    top: 0;
    left: 5px;
    margin-top: 0;
  }

  .unauth-unlock-rectangle {
    flex-direction: column;
    align-items: center;
    width: 100%; /* Adjust the width as needed */
    margin-top: 0;
  }

  .get-started-container {
    margin-top: 0;
  }
}