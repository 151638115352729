html,
body {
    height: 100%;
    margin: 0;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main-content {
    flex: 1;
}

.pageOffset {
    margin-left: 135px;
    margin-right: 135px;
}

h1 {
    color: #333;
    font-size: 3.375rem;
    font-weight: 700;
}

h2 {
    color: #333;
    font-size: 40px;
    font-weight: 700;
}


p {
    color: rgb(118, 118, 118);
    font-size: 24px;
    font-weight: 400;
}