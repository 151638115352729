.operation-record.link-docs {
  font-family: 'SourceSansProSemiBold', 'Source Sans Pro', sans-serif;
  font-size: 1.0em;
  fill: #3775d0;
}

.operation-record.link-api {
  position: relative;
  max-width: 150px;
  top: 80px;
  left: 120px;
  font-family: 'SourceSansProSemiBold', 'Source Sans Pro', sans-serif;
  font-size: 1.0em;
  fill: #fcfcfc;
  padding: 5px;
}
