.authenticated-home {
    position: relative;
}

.authenticated-home.h1 {
    font-size: 3.0em;
    font-weight: bold;
}

.authenticated-home.h2 {
    font-size: 2.5em;
    font-weight: bold;
}

.authenticated-home.h3 {
    font-size: 2.0em;
    font-weight: bold;
}

.authenticated-home.h4 {
    margin-top: 20px;
    font-size: 1.5em;
    font-weight: bold;
}

.authenticated-home.p {
    font-size: 1.0em;
}

@media screen and (max-width: 768px) {
    .authenticated-home h1 {
        font-size: 2.0em;
        font-weight: bold;
    }

    .authenticated-home h2 {
        font-size: 1.5em;
        font-weight: bold;
    }

    .authenticated-home p {
        font-size: 1.0em;
    }
}