.get-started-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -124px;
}

.text-large {
  font-size: 32px; /* Smaller size for mobile */
  font-weight: bold;
  padding-bottom: 20px;
}

.get-started-svg {
  width: 50%; /* Adjust as needed */
  transform: scale(1); /* Default scale */

  @media (max-width: 768px) {
    /*.get-started-container {*/
        display: flex;
        padding-top: 36px;
        transform: scale(1.5);
        align-items: center;
        margin-top: 124px;
    /*}*/
    /*.text-large {*/
    /*  font-size: 24px; !* Smaller size for mobile *!*/
    /*  font-weight: bold;*/
    /*  padding-bottom: 16px;*/
    /*}*/
  }
}