$primary: rgb(55, 117, 208);
$dark: rgb(51, 51, 51);
$body-bg: #ffffff;
$border-radius: 4px;

$font-size-base: 1rem;
$font-weight-base: 400;
$line-height-base: 1.5;

$h1-font-size: $font-size-base * 2.5;
$h1-font-weight: $font-weight-base * 1.75;

$font-family-base: "Source Sans Pro", Helvetica, Arial, Lucida, sans-serif;;

$btn-primary-color: #fff;
$btn-primary-bg: $primary;
$btn-dark-color: $dark;

@import '~bootstrap/scss/bootstrap.scss';

