.footer {
    opacity: 1;
    background-color: #333333;
    color: #ffffff;
    padding: 20px 0;
    padding-top: 48px;
    height: 226px;
    width: 100%;
}

.footer .social-images {
    text-align: left;
    margin-bottom: 20px;
}

.footer .social-image {
    margin-right: 30px;
    margin-bottom: 20px;
}

.footer .logo-image {
    text-align: right;
}

@media (max-width: 768px) {
    .footer .social-images {
        text-align: center;
    }

    .footer .social-image {
        margin-right: 15px;
        margin-left: 15px;
    }

    .footer .logo-image {
        text-align: center;
        padding-bottom: 20px;
        background-color: #333333;
    }
}