/* src/Components/AuthenticatedHome/LifeProductCard.css */
/* To apply this, the product-card must be in a div */
div.product-card {
  font-family: 'SourceSansProRegular', 'Source Sans Pro', sans-serif;
  width: 300px;
  height: 266px;
  display: flex;
  flex-direction: row;
  text-align: left;
  border: 1px solid #cccccc; /* Border color */
}

.product-card.rect {
  border-radius: 4px;
  border: 1px solid rgba(204, 204, 204, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  opacity: 1;
  background-color: rgba(252, 252, 252, 1);

  border-left-width: 10px; /* Left border width to allow for color*/
  stroke: #cccccc;
  stroke-width: 1;
  stroke-linejoin: miter;
  fill: #fcfcfc;
  fill-rule: evenodd;
  flex-direction: column;
}

.product-card.title {
  font-size: 1.25em;
  font-weight: bold;
  fill: #333333;
}

.product-card.description {
  font-size: 1.0em;
  font-weight: normal;
  fill: #767676;
}

/* Specific styles for different product types */
/* Applied at product record */
