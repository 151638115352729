.pill-product {
    background-color: #8B509C;
}

.pill-operation {
    background-color: lightcoral;

}

.pill-api {
    background-color: #40A2A6;
}


.search-center {
    display: flex;
    align-items: center;
}